import { ref } from "vue"
import cockpitExplorerService from "@/js/services/CockpitExplorerService";
import explorerStatusService from "@/js/services/ExplorerStatusService";


export function useExplorerTags({ isCurrentProject = false } = {}) {
  const tagsList = ref([])
  const isLoading = ref(false)
  const errors = ref("")
  const explorerCount = ref(0)

  const loadTags = async () => {
    if (tagsList.value.length > 0) {
      return
    }

    isLoading.value = true
    try {
      if (isCurrentProject) {
        tagsList.value = await cockpitExplorerService.getExplorerTagsForCurrentProjectPromise()
      } else {
        tagsList.value = await cockpitExplorerService.getExplorerTagsPromise()
      }
    } finally {
      isLoading.value = false
    }
  }

  const setValidationErrors = ({ validationData }) => {
    if (!validationData) {
      errors.value = ""
      return ""
    }

    const errorData = validationData;
    let errorMessage = "Tag query syntax error."
    if (errorData.error === "ESS_TAG_QUERY") {
      errorMessage = `${errorData.message.substring(0, 1).toUpperCase()}${errorData.message.slice(1)}.`
    }
    errors.value = errorMessage
    return errorMessage
  }

  const checkSyntax = async (value) => {
    isLoading.value = true
    try {
      const resp = await explorerStatusService.checkTagSyntax(value)
      setValidationErrors({ validationData: null })
      explorerCount.value = resp.count
    } catch (_err) {
      setValidationErrors({ validationData: _err.response.data })
      explorerCount.value = 0
    } finally {
      isLoading.value = false
    }
  }


  return {
    tagsList,
    isLoading,
    loadTags,
    errors,
    checkSyntax,
    explorerCount,
  }
}

