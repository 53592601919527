
export function useHelpButton({ prefix }) {
  const BTN_POSSTFIX = "help_btn";

  const getHelpButtonId = (elementName) => `${prefix}_${elementName}_${BTN_POSSTFIX}`

  return {
    getHelpButtonId,
  }
}
