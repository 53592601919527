import { computed } from "vue"

export const MAP_ORGANIZATIONS_TO_IDS = Object.freeze({
  ACCTOPUS: [213],
  STF_GRUPPE: [679],
})

export default function useOrganizationResolver({id}) {
  const isAcctopusOrg = computed(() => MAP_ORGANIZATIONS_TO_IDS.ACCTOPUS.includes(id))
  const isStfGruppeOrg = computed(() => MAP_ORGANIZATIONS_TO_IDS.STF_GRUPPE.includes(id))

  return {
    isAcctopusOrg,
    isStfGruppeOrg,
  }
}

