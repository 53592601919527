import Service from '@/js/service';

const service = new Service(`${process.env.VUE_APP_COCKPIT_TIME_SCHEDULING_SERVICE}/cockpit-time-scheduling-service`);

const cockpitTimeSchedulingService = {
    getScheduledTestsTable(params, then, error) {
        service.getRequest(1, 'scheduled-tests/table', params, then, error);
    },

    getScheduledTestsTablePromise() {
      return new Promise((resolve, reject) => {
        service.getRequest(1, 'scheduled-tests/table', null, resolve, reject);
      })
    },

    createScheduledTest(scheduledTest, then, error) {
        service.putRequest(1, 'scheduled-test', scheduledTest, then, error);
    },
    updateScheduledTest(scheduledTest, then, error) {
        service.patchRequest(1, 'scheduled-test/{id}', scheduledTest, then, error, scheduledTest.id);
    },
    deleteScheduledTest(id, then, error) {
        service.deleteRequest(1, 'scheduled-test/{id}', null, then, error, id);
    },
    activateScheduledTest(id, then, error) {
        service.patchRequest(1, 'scheduled-test/{id}/activate', null, then, error, id);
    },
    deactivateScheduledTest(id, then, error) {
        service.patchRequest(1, 'scheduled-test/{id}/deactivate', null, then, error, id);
    },
    checkCronSyntax(cron) {
      return new Promise((resolve, reject) => {
        service.getRequest(1, 'check-cron-syntax', {cron: cron.replace('\n', '\\n')}, resolve, reject);
      })
    },
};

export default cockpitTimeSchedulingService;
